import React from "react";
import NavbarV2 from "./navbar-v2";
import Footer from "./footer";
import IubendaCookieConsent from "./iubenda-cookie-consent";
import SEO, { SEOProps } from "./SEO/seo";
import Navbar from "./navbar";
import { Popover } from "@headlessui/react";

type LayoutProps = {
  id: string;
  hasFooter?: boolean;
  hasNavbar?: boolean;
  seo: any;
};

const LayoutV2: React.FC<LayoutProps> = ({
  id,
  hasNavbar = true,
  hasFooter = true,
  seo,
  children,
}): JSX.Element => (
  <Popover id={id} className="relative bg-white overflow-hidden">
    {({ open }) => (
      <>
        <SEO {...seo}></SEO>
        {hasNavbar ? <Navbar open={open} /> : <></>}
        <IubendaCookieConsent></IubendaCookieConsent>
        {children}
        {hasFooter ? <Footer /> : <></>}
      </>
    )}
  </Popover>
);

export default LayoutV2;
